<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import { isAndroid, isIos, isWeixinBrowser } from "./utils/util";
import { ACCESS_TOKEN, LOGIN_USER, ACCESS_TOKEN_EXPIRED_AT } from '@/utils/constants'
import { getChurchByDomain } from '@/api/media'

export default {
    name: 'App',
    async metaInfo() {
        const church = await getChurchByDomain()

        return {
            title: this.defaultName,
            titleTemplate: '%s - ' + church.data.name
        }
    },
    computed: {
        isPc () {
            return !isAndroid() && !isIos()
        }
    },
    provide: {
        isAndroid: isAndroid(),
        isIos: isIos(),
        isPc: !isAndroid() && !isIos(),
        isWeixinBrowser: isWeixinBrowser()
    },
    data () {
        return {
            defaultName: null
        }
    },
    watch: {
        $route(to){
            this.defaultName = to.meta.title
        }
    },
    created() {
        this.$eventBus.$on('login-success', ({ expiration, user, token }) => {
            const expired = expiration * 1000
            this.$ls.set(ACCESS_TOKEN, token, expired);
            this.$ls.set(LOGIN_USER, user, expired);
            this.$ls.set(ACCESS_TOKEN_EXPIRED_AT, new Date().getTime() + expired, expired)
        })
    }
};
</script>

<style>
    body {
        font-size: 16px;
        background-color: #f8f8f8;
        -webkit-font-smoothing: antialiased;
        transition: all .5s cubic-bezier(.55,0,.1,1);
    }
</style>
