import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta'
import { LOGIN_USER } from '@/utils/constants'

Vue.use(VueMeta)
Vue.use(Router);

const routes = [
    {
        name: 'Index',
        path: '/',
        component: () => import('./view/Index'),
        meta: {
            title: '首页'
        }
    },
    {
        name: 'AppDownload',
        path: '/download',
        component: () => import('./view/AppDownload'),
        meta: {
            title: 'APP下载'
        }
    },
    {
        name: 'VideoIndex',
        path: '/video',
        component: () => import('./view/video/Index'),
        meta: {
            title: '视频',
            needLogin: true
        }
    },
    {
        name: 'VideoShow',
        path: '/video/:id',
        component: () => import('./view/video/VideoShow'),
        meta: {
            title: '视频详情',
            needLogin: true
        }
    },
    {
        name: 'AudioIndex',
        path: '/audio',
        component: () => import('./view/audio/Index'),
        meta: {
            title: '音频',
            needLogin: true
        }
    },
    {
        name: 'AudioShow',
        path: '/audio/:id',
        component: () => import('./view/audio/AudioShow'),
        meta: {
            title: '音频详情',
            needLogin: true
        }
    },
    {
        name: 'ReaderIndex',
        path: '/reader',
        component: () => import('./view/reader/Index'),
        meta: {
            title: '阅读',
            needLogin: true
        }
    },
    {
        name: 'ReaderShow',
        path: '/reader/:id',
        component: () => import('./view/reader/ReaderShow'),
        meta: {
            title: '阅读详情',
            needLogin: true
        }
    },
    {
        name: 'UserIndex',
        path: '/user',
        component: () => import('./view/user/Index'),
        meta: {
            title: '我的',
            needLogin: true
        }
    },
    {
        name: 'UserEdit',
        path: '/user/edit',
        component: () => import('./view/user/Edit'),
        meta: {
            title: '信息编辑',
            needLogin: true
        }
    },
    {
        name: 'UserHistory',
        path: '/user/history',
        component: () => import('./view/user/History'),
        meta: {
            title: '浏览记录',
            needLogin: true
        }
    },
    {
        name: 'UserCollect',
        path: '/user/collect',
        component: () => import('./view/user/Collect'),
        meta: {
            title: '我的收藏',
            needLogin: true
        }
    },
    {
        name: 'BibleIndex',
        path: '/bible',
        component: () => import('./view/user/BibleIndex'),
        meta: {
            title: '在线圣经',
            needLogin: true
        }
    },
    {
        name: 'PoemIndex',
        path: '/poem',
        component: () => import('./view/user/PoemIndex'),
        meta: {
            title: '赞美诗',
            needLogin: true
        }
    },
    {
        name: 'SermonIndex',
        path: '/sermon',
        component: () => import('./view/sermon/Index'),
        meta: {
            title: '讲道记录',
            needLogin: true
        }
    },
    {
        name: 'NoteIndex',
        path: '/note',
        component: () => import('./view/user/NoteIndex'),
        meta: {
            title: '听道笔记',
            needLogin: true
        }
    },
    {
        name: 'NoteShow',
        path: '/note/:id',
        component: () => import('./view/user/NoteShow'),
        meta: {
            title: '听道笔记',
            needLogin: true
        }
    },
    {
        name: 'PlaybackIndex',
        path: '/playback',
        component: () => import('./view/user/PlaybackIndex'),
        meta: {
            title: '直播回放',
            needLogin: true
        }
    },
    {
        name: 'PlaybackShow',
        path: '/playback/:id',
        component: () => import('./view/user/PlaybackShow'),
        meta: {
            title: '直播回放',
            needLogin: true
        }
    },
    {
        name: 'SermonCreate',
        path: '/sermon/create',
        component: () => import('./view/sermon/Create'),
        meta: {
            title: '新建讲道记录',
            needLogin: true
        }
    },
    {
        name: 'SermonEdit',
        path: '/sermon/:id/edit',
        component: () => import('./view/sermon/Edit'),
        meta: {
            title: '编辑讲道记录',
            needLogin: true
        }
    },
    {
        name: 'BindPhone',
        path: '/bind',
        component: () => import('./view/user/BindPhone'),
        meta: {
            title: '绑定手机号',
            needLogin: true
        }
    },
    {
        name: 'SSO',
        path: '/sso',
        component: () => import('./view/login/SSO'),
        meta: {
            title: '统一登录'
        }
    },
    {
        name: 'LiveIndex',
        path: '/:code',
        component: () => import('./view/live/Index'),
        meta: {
            title: '在线直播',
            needLogin: true
        }
    },
    {
        path: '*',
        redirect: '/'
    }
];

// add route path
routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});

const router = new Router({
    mode: 'history',
    base: '/',
    routes: routes
})

router.afterEach((to) => {
    let authUrl = `${window.location.origin}${to.fullPath}`;

    if (window.__wxjs_is_wkwebview) {
        if (window.authUrl === '' || window.authUrl === undefined) {
            window.authUrl = authUrl
        }
    } else {
        window.authUrl = authUrl
    }
})
router.beforeEach((to, from, next) => {
    if (!to.meta.needLogin) {
        next()
    } else {
        let user = Vue.ls.get(LOGIN_USER)
        if (!user && to.path !== '/') {
            next({ path: '/', query: { redirect: to.path } })
        } else {
            const redirect = from.query.redirect
            if(to.path === redirect) {
                next()
            } else {
                if (redirect) {
                    next({ path: redirect, replace: true, query: to.query })
                } else{
                    next()
                }
            }
            
        }
    }
})

export {
    router
};
