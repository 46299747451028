import {axios} from '@/utils/request'

export function getVideos(parameter) {
    parameter = Object.assign({type: 'video'}, parameter)
    return axios({
        url: 'medias',
        method: 'get',
        params: parameter
    })
}

export function getAudios(parameter) {
    parameter = Object.assign({type: 'audio'}, parameter)
    return axios({
        url: 'medias',
        method: 'get',
        params: parameter
    })
}

export function getMedia(mediaId) {
    return axios({
        url: `medias/${mediaId}`,
        method: 'get'
    })
}

export function getChurchByDomain(params) {
    return axios({
        url: 'medias/church',
        method: 'get',
        params: params
    })
}
