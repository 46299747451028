import Vue from 'vue'
import axios from 'axios'
import { Notify } from 'vant';
import { ACCESS_TOKEN, LOGIN_USER } from '@/utils/constants'


// 创建 axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL +  process.env.VUE_APP_API_BASE_URL_PREFIX, // api base_url
    timeout: 30000, // 请求超时时间
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json'
    }
})

const err = (error) => {
    if (error.response) {
        if (error.response.status === 500) {
            const msg = error.response.data.message || '服务器错误.'
            Notify({type: 'danger', message: msg})
        } else {
            const data = error.response.data
            Notify({type: 'warning', message: data.message})

            if (error.response.status === 401) {
                Vue.ls.remove(ACCESS_TOKEN)
                Vue.ls.remove(LOGIN_USER)
            }
        }
    }
    return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
    const token = Vue.ls.get(ACCESS_TOKEN)
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    config.headers['X-TJCMedium-Domain'] = window.location.origin
    return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
    return response.data
}, err)

const installer = {
    vm: {},
    install(Vue) {
        Vue.use({
            vm: {},
            // eslint-disable-next-line no-unused-vars
            install(Vue, instance) {
                if (this.installed) {
                    return
                }
                this.installed = true

                if (!instance) {
                    // eslint-disable-next-line no-console
                    console.error('You have to install axios')
                    return
                }

                Vue.axios = instance

                Object.defineProperties(Vue.prototype, {
                    axios: {
                        get: function get() {
                            return instance
                        }
                    },
                    $http: {
                        get: function get() {
                            return instance
                        }
                    }
                })
            }
        }, service)
    }
}

export {
    installer as VueAxios,
    service as axios
}
