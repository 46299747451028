import Vue from 'vue'
import {
    Tab,
    Tabs,
    Tabbar,
    TabbarItem,
    Skeleton,
    Panel,
    List,
    Swipe,
    SwipeItem,
    Lazyload,
    Image,
    Loading,
    Col,
    Row,
    Icon,
    Search,
    Cell,
    CellGroup,
    Toast,
    ActionSheet,
    Form,
    Button,
    Field,
    Calendar,
    Picker,
    Popup,
    Uploader,
    Grid,
    GridItem,
    Sticky,
    ImagePreview,
    Dialog,
    Empty,
    NoticeBar,
    CountDown,
    Checkbox,
    CheckboxGroup,
    RadioGroup,
    Radio,
    Stepper,
    Tag
} from 'vant'

Vue.use(Stepper)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Skeleton)
Vue.use(Panel)
Vue.use(List)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Lazyload)
Vue.use(Image)
Vue.use(Loading)
Vue.use(Col)
Vue.use(Row)
Vue.use(Icon)
Vue.use(Search)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Toast)
Vue.use(ActionSheet)
Vue.use(Form)
Vue.use(Button)
Vue.use(Field)
Vue.use(Calendar)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(Uploader)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Sticky)
Vue.use(ImagePreview)
Vue.use(Dialog)
Vue.use(Empty)
Vue.use(NoticeBar)
Vue.use(CountDown)
Vue.use(Tag)
