import Vue from 'vue'
import App from './App'
import { router } from './router'
import { VueAxios } from './utils/request'
import './utils/ls'
import './utils/use'
import 'vant/lib/icon/local.css'
import 'intersection-observer'
import VueLazyComponent from '@xunlei/vue-lazy-component'

Vue.use(VueLazyComponent)
Vue.prototype.$eventBus = new Vue({
    data () {
        return {
            isLogging: false
        }
    }
})

Vue.use(VueAxios)

new Vue({
    router,
    el: '#app',
    render: h => h(App)
});
