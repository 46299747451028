export const ACCESS_TOKEN = 'ACCESS_TOKEN'
export const ACCESS_TOKEN_EXPIRED_AT = 'ACCESS_TOKEN_EXPIRED_AT'
export const LOGIN_USER = 'LOGIN_USER'
export const FINGER_PRINT = 'FINGER_PRINT'
export const WECHAT_OPEN_ID = 'WECHAT_OPEN_ID'
export const SUBSCRIBE_TOKEN = 'SUBSCRIBE_TOKEN'
export const LIVE_START_SCENE = 1001

export function VOLUME_CHAPTER_VERSES(volumeId, chapterId) {
    return `VOLUME::${volumeId}::CHAPTER::${chapterId}::VERSES`
}
export function VOLUME_CHAPTERS(volumeId) {
    return 'VOLUME::' + volumeId + '::CHAPTERS'
}
